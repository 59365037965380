import * as React from "react";
import { Link } from "gatsby";
import MainTemplate from "../components/Template/MainTemplate";
import { Helmet } from "react-helmet"

const NotFoundPage = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>404 - Dicka Ismaji</title>
      </Helmet>
      <div className="text-center py-12">
        <h1>Page is not found</h1>
        <Link to="/">Home page</Link>
      </div>
    </MainTemplate>
  );
};

export default NotFoundPage;
